<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.75"
      y="4.75"
      width="18.5"
      height="16.5"
      rx="2.25"
      :fill="color"
      :stroke="color ? color : stroke"
      stroke-width="1.5"
    />
    <path
      d="M9 12.7778L11.25 15L15 11"
      :stroke="stroke"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 2.75V4.75"
      :stroke="color ? color : stroke"
      stroke-width="1.2"
      stroke-linecap="round"
    />
    <path
      d="M17 2.75V4.75"
      :stroke="color ? color : stroke"
      stroke-width="1.2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconReservation',
  props: {
    color: {
      type: String,
      default: null,
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
