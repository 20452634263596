export const createService = (client) => {
  return {
    async getSessions(pagination) {
      const params = {
        page: pagination.page || 1,
        fields:
          'success,errorCode,eventSessions(id,title,startDate,endDate,startTime,endTime,location,locationUrl,fee,invitee,dueDate,contents,registrationUrl,images,isExpired)',
      }
      if (pagination.size) params.size = pagination.size
      return client.get(`/mobile/communityEventSessions`, {
        params,
      })
    },
    async getBannerSessions(pagination) {
      const params = {
        page: pagination.page || 1,
        isBannerEvent: true,
        isExpired: false,
        fields:
          'success,errorCode,eventSessions(id,title,startDate,endDate,startTime,endTime,location,locationUrl,fee,invitee,dueDate,contents,registrationUrl,images,isExpired)',
      }
      if (pagination.size) params.size = pagination.size
      return client.get(`/mobile/communityEventSessions`, {
        params,
      })
    },
    getSession(id) {
      const params = {
        fields:
          'success,errorCode,eventSession(id,title,startDate,endDate,startTime,endTime,location,locationUrl,fee,invitee,dueDate,contents,registrationUrl,images,isExpired)',
      }
      return client.get(`/mobile/communityEventSessions/${id}`, {
        params,
      })
    },
  }
}
