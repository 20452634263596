<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <template v-if="type === 'off'">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.75 4.5C9.75 4.29289 9.91789 4.125 10.125 4.125H19.5C19.7071 4.125 19.875 4.29289 19.875 4.5V19.5C19.875 19.7071 19.7071 19.875 19.5 19.875H13.5V14.3256C13.5 13.8699 13.2929 13.4389 12.937 13.1543L9.7499 10.6046L9.75 10.5938V4.5ZM8.625 9.8731V4.5C8.625 3.67157 9.29657 3 10.125 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H12.9375L12.9354 21H4.5C3.67157 21 3 20.3284 3 19.5V14.3256C3 13.8699 3.20713 13.4389 3.56296 13.1543L7.31296 10.1543C7.69101 9.85184 8.17888 9.75811 8.625 9.8731ZM8.48426 11.0328C8.34731 10.9232 8.1527 10.9232 8.01574 11.0328L4.26574 14.0328C4.17678 14.1039 4.125 14.2117 4.125 14.3256V19.5C4.125 19.7071 4.29289 19.875 4.5 19.875H12.375V14.3256C12.375 14.2117 12.3232 14.1039 12.2343 14.0328L8.48426 11.0328ZM6.28123 17.625C6.28123 17.3143 6.53307 17.0625 6.84373 17.0625H9.65623C9.96689 17.0625 10.2187 17.3143 10.2187 17.625C10.2187 17.9357 9.96689 18.1875 9.65623 18.1875H6.84373C6.53307 18.1875 6.28123 17.9357 6.28123 17.625ZM12.9375 6.75002C13.2482 6.75002 13.5 7.00186 13.5 7.31252V7.32189C13.5 7.63255 13.2482 7.88439 12.9375 7.88439C12.6268 7.88439 12.375 7.63255 12.375 7.32189V7.31252C12.375 7.00186 12.6268 6.75002 12.9375 6.75002ZM17.25 7.31252C17.25 7.00186 16.9982 6.75002 16.6875 6.75002C16.3768 6.75002 16.125 7.00186 16.125 7.31252V7.32189C16.125 7.63255 16.3768 7.88439 16.6875 7.88439C16.9982 7.88439 17.25 7.63255 17.25 7.32189V7.31252ZM16.6875 10.5C16.9982 10.5 17.25 10.7519 17.25 11.0625V11.0719C17.25 11.3826 16.9982 11.6344 16.6875 11.6344C16.3768 11.6344 16.125 11.3826 16.125 11.0719V11.0625C16.125 10.7519 16.3768 10.5 16.6875 10.5ZM17.25 14.8125C17.25 14.5018 16.9982 14.25 16.6875 14.25C16.3768 14.25 16.125 14.5018 16.125 14.8125V14.8219C16.125 15.1325 16.3768 15.3844 16.6875 15.3844C16.9982 15.3844 17.25 15.1325 17.25 14.8219V14.8125Z"
        :fill="stroke"
      />
    </template>
    <template v-else>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.625 9.87306V4.5C8.625 3.67157 9.29658 3 10.125 3H19.5C20.3284 3 21 3.67157 21 4.5V19.5C21 20.3284 20.3284 21 19.5 21H12.9375L12.9304 21H4.5C3.67157 21 3 20.3284 3 19.5V14.3255C3 13.8699 3.20713 13.4389 3.56296 13.1542L7.31296 10.1542C7.69101 9.8518 8.17888 9.75807 8.625 9.87306ZM12.9375 6.75002C13.2482 6.75002 13.5 7.00186 13.5 7.31252V7.3219C13.5 7.63256 13.2482 7.8844 12.9375 7.8844C12.6268 7.8844 12.375 7.63256 12.375 7.3219V7.31252C12.375 7.00186 12.6268 6.75002 12.9375 6.75002ZM17.25 7.31252C17.25 7.00186 16.9982 6.75002 16.6875 6.75002C16.3768 6.75002 16.125 7.00186 16.125 7.31252V7.3219C16.125 7.63256 16.3768 7.8844 16.6875 7.8844C16.9982 7.8844 17.25 7.63256 17.25 7.3219V7.31252ZM16.6875 10.5C16.9982 10.5 17.25 10.7519 17.25 11.0625V11.0719C17.25 11.3826 16.9982 11.6344 16.6875 11.6344C16.3768 11.6344 16.125 11.3826 16.125 11.0719V11.0625C16.125 10.7519 16.3768 10.5 16.6875 10.5ZM17.25 14.8125C17.25 14.5019 16.9982 14.25 16.6875 14.25C16.3768 14.25 16.125 14.5019 16.125 14.8125V14.8219C16.125 15.1326 16.3768 15.3844 16.6875 15.3844C16.9982 15.3844 17.25 15.1326 17.25 14.8219V14.8125ZM6.32813 17.625C6.32813 17.3402 6.55899 17.1094 6.84376 17.1094H9.65626C9.94103 17.1094 10.1719 17.3402 10.1719 17.625C10.1719 17.9098 9.94103 18.1406 9.65626 18.1406H6.84376C6.55899 18.1406 6.32813 17.9098 6.32813 17.625ZM9.99181 10.671C9.77559 10.4857 9.45008 10.5107 9.26475 10.727C9.07942 10.9432 9.10446 11.2687 9.32068 11.454L12.2745 13.9859C12.3681 14.066 12.4219 14.1831 12.4219 14.3062V19.5C12.4219 19.7848 12.6527 20.0157 12.9375 20.0157C13.2223 20.0157 13.4531 19.7848 13.4531 19.5V14.3062C13.4531 13.882 13.2678 13.479 12.9457 13.2029L9.99181 10.671Z"
        :fill="color"
      />
    </template>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import colors from '@/assets/scss/base/colors-v2.module.scss'

export default defineComponent({
  name: 'IconGroup',
  props: {
    color: {
      type: String,
      default: colors['f-primary-black'],
    },
    stroke: {
      type: String,
      default: colors['f-primary-black'],
    },
    type: {
      type: String,
      default: 'off',
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
})
</script>
