import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-edcfa430"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag-slider--container" }
const _hoisted_2 = { class: "tag-slider--nav tag-slider--nav--prev" }
const _hoisted_3 = { class: "tag-slider--nav tag-slider--nav--next" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_SwiperSlide = _resolveComponent("SwiperSlide")!
  const _component_Swiper = _resolveComponent("Swiper")!
  const _component_Scroller = _resolveComponent("Scroller")!

  return (_openBlock(), _createElementBlock("div", {
    class: "ff-tag-slider",
    style: _normalizeStyle(_ctx.cssVars)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Swiper, {
        class: "tag-slider--swiper",
        modules: _ctx.swiperOptions.modules,
        speed: _ctx.swiperOptions.speed,
        autoHeight: _ctx.swiperOptions.autoHeight,
        allowTouchMove: _ctx.swiperOptions.allowTouchMove,
        spaceBetween: _ctx.swiperOptions.spaceBetween,
        slidesPerView: _ctx.swiperOptions.slidesPerView,
        freeMode: _ctx.swiperOptions.freeMode,
        onSwiper: _ctx.setSwiperController,
        onResize: _ctx.checkScrollable
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (row, key) => {
            return (_openBlock(), _createBlock(_component_SwiperSlide, {
              key: key,
              class: "tag-slider--item"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Tag, {
                  theme: row.theme,
                  selected: row.selected,
                  onClick: () => _ctx.onItemClick(row)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(row.name), 1)
                  ]),
                  _: 2
                }, 1032, ["theme", "selected", "onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules", "speed", "autoHeight", "allowTouchMove", "spaceBetween", "slidesPerView", "freeMode", "onSwiper", "onResize"])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Scroller, {
        type: "dropshadow",
        direction: "left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slideTo('prev')))
      })
    ], 512), [
      [_vShow, _ctx.state.progress !== 0 && _ctx.state.isScrollable]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Scroller, {
        type: "dropshadow",
        direction: "right",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.slideTo('next')))
      })
    ], 512), [
      [_vShow, _ctx.state.progress !== 1 && _ctx.state.isScrollable]
    ])
  ], 4))
}