import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-42fb29f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-history--container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "search-history--delete"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButtonDelete = _resolveComponent("IconButtonDelete")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ff-search-history", {
      'search-history-clickable': _ctx.isClickable,
      'search-history-deleteable': _ctx.isDeleteable,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "search-history--keyword",
        onClick: _cache[0] || (_cache[0] = () => _ctx.isClickable && _ctx.onClick())
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
            (block.tag === 'b')
              ? (_openBlock(), _createElementBlock("b", _hoisted_2, _toDisplayString(block.text), 1))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(block.text), 1)
                ], 64))
          ], 64))
        }), 128))
      ]),
      (_ctx.isDeleteable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_IconButtonDelete, { onClick: _ctx.onDelete }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}