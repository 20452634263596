import { UniversalTrackingParamsSchema } from '@fastfive-dev/frontend-library/dist/fastfive/libs/universal-tracking'
import firebase from 'firebase/app'
import { z } from 'zod'

import { version as PKG_VERSION } from '../../../package.json'

import { IS_SERVE, IS_TEST } from '@/env'
import {
  EventName,
  EventParams,
  EventParamsCustom,
} from '@/interfaces/firebase/analytics'

const debugLog = (name: string, ...args: any[]) => {
  console.log(`%c${name}`, 'color: yellow', ...args)
}

export class Analytics {
  #analytics: firebase.analytics.Analytics | null = null

  /**
   * 이 값을 true로 변경시, GA 로그를 console로 확인할 수 있음
   */
  #debug = false

  constructor(analytics?: firebase.analytics.Analytics) {
    // 개발모드와 테스트모드에서 GA를 실행하지 않습니다.
    if (IS_SERVE || IS_TEST) return

    this.#analytics = analytics || firebase.analytics()

    this.setUserProperties({
      version: PKG_VERSION,
    })
  }

  setUserId(userId: number) {
    if (this.#debug) {
      debugLog('Analytics.setUserId', userId)
    }

    if (!this.#analytics) return

    if (userId) {
      this.#analytics.setUserId(String(userId))
      this.setUserProperties({
        member_id: userId,
      })
    }
  }

  setUserProperties(
    payload: {
      member_id?: number | null
      member_group_id?: number | null
      version?: string
    } = {},
  ) {
    if (this.#debug) {
      debugLog('Analytics.setUserProperties', payload)
    }

    if (!this.#analytics) return

    const userProperties = {}
    let hasUserProperties = false

    Object.keys(payload).forEach((key) => {
      const val = payload[key] ?? null

      if (val !== null) {
        hasUserProperties = true
        userProperties[key] = String(val)
      }
    })

    if (hasUserProperties) {
      this.#analytics.setUserProperties(userProperties)
    }
  }

  screenView(screenName) {
    if (this.#debug) {
      debugLog('Analytics.screenView', screenName)
    }

    if (this.#analytics) {
      this.#analytics.setCurrentScreen(screenName)
    }

    switch (screenName) {
      case 'home_main':
        this.logEvent(screenName, {
          category: 'home',
        })
        break

      case 'reservation_main':
      case 'reservation_history_main':
        this.logEvent(screenName, {
          category: 'reservation',
        })
        break

      case 'membermanagement_main':
        this.logEvent(screenName, {
          category: 'groupmanagement',
        })
        break

      case 'benefits_main':
      case 'benefits_main_detail_benefits':
      case 'eats_main':
        this.logEvent(screenName, {
          category: 'benefits',
        })
        break
    }
  }

  logEvent(eventName: string, params?: EventParams) {
    if (eventName.length > 40) {
      console.warn('글자수 제한(40자) 초과')
    }

    if (this.#debug) {
      const logMsgs: [string, ...any] = ['Analytics.logEvent', eventName]

      if (params) {
        logMsgs.push(params)
      }

      debugLog(...logMsgs)
    }

    if (!this.#analytics) return

    this.#analytics.logEvent(eventName, params)

    this.universalTracking({
      ut_tracking_key: eventName,
      ut_data: {
        ...params,
      },
    })
  }

  /**
   * UniversalTracking
   *
   * - GA 정의서의 universal_tracking 시트 참고 (https://docs.google.com/spreadsheets/d/1g5whHOEmWYYYYVQ4i_gHxiKKZXBdQ4lQZeHHbVDcKkw/edit#gid=1820578972)
   */
  universalTracking(params: z.input<typeof UniversalTrackingParamsSchema>) {
    const result = UniversalTrackingParamsSchema.safeParse(params)

    if (this.#debug) {
      debugLog(
        'Analytics.universalTracking',
        result.success ? result.data : result.error,
      )
    }

    if (result.success) {
      this.#analytics?.logEvent('universal_tracking', result.data)
    }
  }

  /**
   * 홈 메뉴 진입 (= 좌측 상단 패파 로고)
   * 최초 진입 시 홈 선택 상태는 카운트 안 함
   */
  presetLogEvent(eventName: 'GNB_home'): void
  /**
   * 우측 상단 내계정 팝업 진입
   */
  presetLogEvent(eventName: 'GNB_account'): void
  /**
   * 지점 영역 클릭 수
   */
  presetLogEvent(eventName: 'GNB_selectbranch'): void
  /**
   * 지점 드롭다운에서 다른지점 클릭 수
   */
  presetLogEvent(eventName: 'GNB_selectbranch_other'): void
  /**
   * 예약하기 메뉴 진입
   */
  presetLogEvent(eventName: 'LNB_reservation'): void
  /**
   * 예약 내역 메뉴 진입
   */
  presetLogEvent(eventName: 'LNB_reservation_history'): void
  /**
   * 제휴 혜택 메뉴 진입
   */
  presetLogEvent(eventName: 'LNB_benefits'): void
  /**
   * 멤버 관리 메뉴 진입
   */
  presetLogEvent(eventName: 'LNB_groupmanagement_membermanagement'): void
  /**
   * 크레딧 관리 메뉴 진입
   */
  presetLogEvent(eventName: 'LNB_groupmanagement_creditsmanagement'): void
  /**
   * 1:1 문의하기
   */
  presetLogEvent(eventName: 'LNB_contact'): void
  /**
   * 오늘의 예약 내역 진입 (=Today, 000님의 예약 → 클릭)
   */
  presetLogEvent(eventName: 'home_reservation_history'): void
  /**
   * 자주 묻는 질문 진입
   */
  presetLogEvent(eventName: 'home_FAQ'): void
  /**
   * 메인게시 공지 클릭 수 총합
   */
  presetLogEvent(eventName: 'home_notice_main'): void
  /**
   * 공지사항 메뉴 진입 (우측 인디케이터 +버튼 클릭)
   */
  presetLogEvent(eventName: 'home_notice'): void
  /**
   * 공지사항 리스트에서 공지사항 상세 진입 총합
   */
  presetLogEvent(eventName: 'notice_details'): void
  /**
   * 공지사항 상세에서 목록으로 돌아가기 버튼 클릭 수 총합
   */
  presetLogEvent(eventName: 'notice_details_back'): void
  /**
   * 지점이용안내 메뉴 진입
   */
  presetLogEvent(eventName: 'home_operationguide'): void
  /**
   * 최상단 이미지 스크롤러(<,>) 클릭 수 총합
   */
  presetLogEvent(eventName: 'operationguide_top_image_scroller'): void
  /**
   * 최상단 이미지 섬네일 클릭 수 총합
   */
  presetLogEvent(eventName: 'operationguide_top_image_thumbnail'): void
  /**
   * 주소 및 우편번호 복사하기 버튼 클릭 수
   */
  presetLogEvent(eventName: 'operationguide_copy1'): void
  /**
   * 지점 특이사항 펼쳐보기 버튼 클릭 수 총합
   */
  presetLogEvent(eventName: 'operationguide_slot_expand'): void
  /**
   * 상단 검색영역 클릭 수 (검색 전/완료 총합)
   */
  presetLogEvent(eventName: 'FAQ_search'): void
  /**
   * 전체 카테고리 탭 클릭 수 총합
   */
  presetLogEvent(eventName: 'FAQ_category'): void
  /**
   * 카테고리 보기 펼쳐보기 버튼 클릭 수 총합
   */
  presetLogEvent(eventName: 'FAQ_slot_expand'): void
  /**
   * 문서 클릭 수 총합
   */
  presetLogEvent(eventName: 'FAQ_details'): void
  /**
   * 문구 응모 메뉴 진입
   */
  presetLogEvent(eventName: 'main_quote'): void
  /**
   * 패파 생활 시작하기 메뉴 진입
   */
  presetLogEvent(eventName: 'main_onboarding'): void
  /**
   * 복합기/네트워크 이용 가이드 버튼 클릭 수
   */
  presetLogEvent(eventName: 'onboarding_printer'): void
  /**
   * 공간 예약 안내 버튼 클릭 수
   */
  presetLogEvent(eventName: 'onboarding_reservation'): void
  /**
   * 그룹 및 멤버 관리 가이드 버튼 클릭 수
   */
  presetLogEvent(eventName: 'onboarding_group'): void
  /**
   * 모바일 출입카드 가이드 버튼 클릭 수
   */
  presetLogEvent(eventName: 'onboarding_entrance'): void
  /**
   * 로그아웃 수
   */
  presetLogEvent(eventName: 'account_logout'): void
  /**
   * 내 계정 관리 진입
   */
  presetLogEvent(eventName: 'account_myaccountupdate'): void
  /**
   * 비밀번호 변경 진입
   */
  presetLogEvent(eventName: 'account_resetpassword'): void
  /**
   * 내 계정 팝업에서 그룹 변경한 수
   * 최초 진입 시 선택된 그룹은 카운트 안 함
   */
  presetLogEvent(eventName: 'account_selectgroup'): void
  /**
   * 소속그룹 초대링크 직접 입력 버튼 클릭 수
   */
  presetLogEvent(eventName: 'account_enter_invitationlink'): void
  /**
   * 날짜 영역 클릭 수
   */
  presetLogEvent(eventName: 'reservation_selectcalendar'): void
  /**
   * 오늘 버튼 클릭 수
   */
  presetLogEvent(eventName: 'reservation_todaybutton'): void
  /**
   * 정렬 버튼 탭 후 기본순 탭 수 (최초 진입 시 선택 상태(Defalut)는 카운트 안 함)
   */
  presetLogEvent(eventName: 'space_list_selectsort_default'): void
  /**
   * 적은 인원 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_smallnumber'): void
  /**
   * 많은 인원 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_largenumber'): void
  /**
   * 낮은 층 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_lowfloor'): void
  /**
   * 높은 층 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_highfloor'): void
  /**
   * 공간이름 A-Z 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_atoz'): void
  /**
   * 공간이름 Z-A 순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_ztoa'): void
  /**
   * 혼잡도 낮은순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_lowcapacity'): void
  /**
   * 혼잡도 높은순 클릭 수
   */
  presetLogEvent(eventName: 'space_list_selectsort_highcapacity'): void
  /**
   * 예약 가능시간 물음표 아이콘 호버해서 3초 이상 툴팁 노출된 수
   */
  presetLogEvent(eventName: 'reservation_credits'): void
  /**
   * 모든 예약 팝업 내 날짜 영역 클릭 수
   */
  presetLogEvent(eventName: 'reservation_popup_calendar'): void
  /**
   * 모든 예약 팝업 내 시작시간 영역 클릭 수
   */
  presetLogEvent(eventName: 'reservation_popup_starttime'): void
  /**
   * 모든 예약 팝업 내 시작시간 필터링 후 값 선택한 수
   */
  presetLogEvent(eventName: 'reservation_popup_starttime_enter'): void
  /**
   * 모든 예약 팝업 내 종료시간 영역 클릭 수
   */
  presetLogEvent(eventName: 'reservation_popup_endtime'): void
  /**
   * 모든 예약 팝업 내 종료시간 필터링 후 값 선택한 수
   */
  presetLogEvent(eventName: 'reservation_popup_endtime_enter'): void
  /**
   * 예약된 시간막대에서 클릭해서 예약내역상세 팝업 출력되는 모든 클릭 수
   * → 내 예약 + 같은 그룹의 예약
   */
  presetLogEvent(eventName: 'reservation_details_profile'): void
  /**
   * 예약 > 모버스전용 공간유형 탭 수 (모버스 전용이 첫 화면일때 최초 진입시는 카운트 하지않음.)
   */
  presetLogEvent(eventName: 'reservation_spacecategory_mvmr'): void
  /**
   * 예약 > 미팅룸 공간유형 탭 수 (미팅룸이 첫 화면일때 최초 진입시는 카운트 하지않음.)
   */
  presetLogEvent(eventName: 'reservation_spacecategory_mr'): void
  /**
   * 예약 > 리차징존 공간유형 탭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_rz'): void
  /**
   * 예약 > 세미나룸 공간유형 탭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_sr'): void
  /**
   * 예약 > 스튜디오 공간유형 탭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_st'): void
  /**
   * 예약 > 라운지 공간유형 탭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_lg'): void
  /**
   * 예약 > 외부대관 공간유형 클릭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_es'): void
  /**
   * 예약 > 미팅부스 공간유형 클릭 수
   */
  presetLogEvent(eventName: 'reservation_spacecategory_mb'): void
  /**
   * 필터 클릭 후 수용인원의 버튼(2인이상~10인이상) 선택해서 결과보기 버튼 클릭한 수. 필터 삭제(x) 버튼 클릭수는 제외
   */
  presetLogEvent(eventName: 'reservation_filter_capacity'): void
  /**
   * 필터 클릭 후 시간대 필터 적용 후 결과보기 버튼 클릭한 수. 필터 삭제(x) 버튼 클릭수는 제외.
   */
  presetLogEvent(eventName: 'reservation_timefiltering'): void
  /**
   * 필터 클릭 후 설비 필터 적용 후 결과보기 버튼 클릭한 수. 필터 삭제(x) 버튼 클릭 수는 제외.
   */
  presetLogEvent(eventName: 'reservation_filter_facilities'): void
  /**
   * 예약 내역 > 그룹 예약 Tab 클릭 수
   */
  presetLogEvent(eventName: 'reservation_history_groupreservation'): void
  /**
   * 예약 내역 > 그룹 예약 Tab > 예약 내역 다운로드 클릭 수
   */
  presetLogEvent(
    eventName: 'reservation_history_groupreservation_download',
  ): void
  /**
   * 취소 정책 클릭 수
   */
  presetLogEvent(eventName: 'reservation_history_cancellationpolicy'): void
  /**
   * 예약 내역 > 내 예약 Tab > 예약 내역 선택해 예약내역상세 팝업 출력 수
   */
  presetLogEvent(eventName: 'reservation_history_mydetail'): void
  /**
   * 예약 내역 > 그룹 예약 Tab > 예약 내역 선택해 예약내역상세 팝업 출력 수
   */
  presetLogEvent(eventName: 'reservation_history_groupdetail'): void
  /**
   * 예약내역 > 내예약 캘린더 영역 클릭 수
   */
  presetLogEvent(eventName: 'reservation_history_calendar'): void
  /**
   * 멤버 초대하기+ 버튼 클릭 수
   */
  presetLogEvent(eventName: 'membermanagement_invitationlink_copy'): void
  /**
   * 그룹 관리 > 크레딧 관리 > 크레딧 정책 버튼 클릭 수
   */
  presetLogEvent(eventName: 'creditsmanagement_creditspolicy'): void
  /**
   * 그룹 관리 > 크레딧 관리 > 이용 내역 다운로드 버튼 클릭 수
   */
  presetLogEvent(eventName: 'creditsmanagement_download'): void
  /**
   * 회사 소개
   */
  presetLogEvent(eventName: 'footer_about_us'): void
  /**
   * 미디어
   */
  presetLogEvent(eventName: 'footer_media'): void
  /**
   * 비즈니스 제휴 문의
   */
  presetLogEvent(eventName: 'footer_businesspartnership_email'): void
  /**
   * 부동산 입점 문의
   */
  presetLogEvent(eventName: 'footer_realestate_email'): void
  /**
   * 언론 홍보 문의
   */
  presetLogEvent(eventName: 'footer_press_email'): void
  /**
   * 서비스 이용약관
   */
  presetLogEvent(eventName: 'footer_termsofservice'): void
  /**
   * 개인정보 처리방침
   */
  presetLogEvent(eventName: 'footer_privacypolicy'): void
  /**
   * Footer SNS
   */
  presetLogEvent(
    eventName: `footer_SNS_${
      | 'facebook'
      | 'instagram'
      | 'naverblog'
      | 'youtube'}`,
  ): void
  /**
   * 1:1 문의하기 > 탭 : 문의하기
   */
  presetLogEvent(eventName: 'inquiry_register_tab'): void
  /**
   * 1:1 문의하기 > 탭 : 문의내역
   */
  presetLogEvent(eventName: 'inquiry_inquiries_tab'): void
  /**
   * 1:1 문의내역 > 상세아이템 클릭
   */
  presetLogEvent(eventName: 'LNB_list_inquiries'): void
  /**
   * 당신을 위한 추천 베네핏 내 모든 슬롯 클릭 수 (홈, 베네핏 상세 페이지 총합)
   */
  presetLogEvent(eventName: 'benefits_recommend'): void
  /**
   * 카테고리 전체보기 클릭 수
   */
  presetLogEvent(eventName: 'benefits_main_category_all'): void
  /**
   * 추천 전체보기 클릭 수
   */
  presetLogEvent(eventName: 'benefits_main_recommend_all'): void
  /**
   * 제휴 혜택 서브 홈 검색 영역 클릭 수
   */
  presetLogEvent(eventName: 'benefits_main_search'): void
  /**
   * 베네핏 이용&제휴 문의 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_main_ask'): void
  /**
   * 일반리스트 검색 영역 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_search'): void
  /**
   * 일반리스트 상단 2뎁스 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_2depth'): void
  /**
   * 일반리스트 정렬 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_selectsort'): void
  /**
   * 일반리스트 정렬 최신순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_selectsort_latest'): void
  /**
   * 일반리스트 정렬 가나다순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_selectsort_Alphabet'): void
  /**
   * 일반리스트 정렬 인기순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_selectsort_random'): void
  /**
   * 일반리스트 불편접수 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_normal_complaint'): void
  /**
   * 추천리스트 검색 영역 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_recommend_search'): void
  /**
   * 추천리스트 정렬 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_recommend_selectsort'): void
  /**
   * 추천리스트 정렬 최신순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_recommend_selectsort_latest'): void
  /**
   * 추천리스트 정렬 가나다순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_recommend_selectsort_Alphabet'): void
  /**
   * 추천리스트 정렬 인기순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_recommend_selectsort_random'): void
  /**
   * 큐레이션리스트 검색 영역 클릭 수
   */
  presetLogEvent(eventName: 'benefits_list_curation_search'): void
  /**
   * 모든 베네핏 상세 페이지에서 사용방법 tab 클릭 수 총합
   */
  presetLogEvent(eventName: 'benefits_detail_tab_howtouse'): void
  /**
   * 모든 베네핏 상세 페이지에서 상세설명 tab 클릭 수 총합
   */
  presetLogEvent(eventName: 'benefits_detail_tab_description'): void
  /**
   * 검색결과 검색 영역 클릭 수
   *
   * @deprecated 코드는 존재하지만 더 이상 사용되지 않음 (2023-09-21)
   */
  presetLogEvent(eventName: 'benefits_searchlist_search'): void
  /**
   * 제휴 혜택 tab 클릭 수 (검색 결과로 넘어온 수는 제외)
   */
  presetLogEvent(eventName: 'benefits_searchlist_benefitstab'): void
  /**
   * 검색결과 정렬 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_searchlist_selectsort'): void
  /**
   * 검색결과 정렬 최신순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_searchlist_selectsort_latest'): void
  /**
   * 검색결과 정렬 가나다순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_searchlist_selectsort_Alphabet'): void
  /**
   * 검색결과 정렬 인기순 클릭 수
   */
  presetLogEvent(eventName: 'benefits_searchlist_selectsort_random'): void
  /**
   * 검색결과 불편접수 버튼 클릭 수
   */
  presetLogEvent(eventName: 'benefits_searchlist_complaint'): void
  /**
   * 카테고리 별 클릭 수(홈, 검색레이어 총합) -> 카테고리&멤버ID
   */
  presetLogEvent(
    eventName: 'benefits_category',
    params: Pick<EventParamsCustom, 'category_id'>,
  ): void
  /**
   * 배너 별 클릭 수 -> 배너 ID
   */
  presetLogEvent(
    eventName: 'benefits_main_banner',
    params: Pick<EventParamsCustom, 'banner_id'>,
  ): void
  /**
   * 큐레이션 별 슬롯 별 클릭 수 -> 큐레이션&베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_main_curation_benefits',
    params: Pick<EventParamsCustom, 'curation_id' | 'benefits_id'>,
  ): void
  /**
   * 큐레이션 별 전체보기 클릭 수 -> 큐레이션&멤버ID
   */
  presetLogEvent(
    eventName: 'benefits_main_curation_all',
    params: Pick<EventParamsCustom, 'curation_id'>,
  ): void
  /**
   * 베네핏 플러스 슬롯 별 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_main_plus',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * [카테고리 레이어] 2뎁스 별 클릭 수 -> 카테고리 ID
   */
  presetLogEvent(
    eventName: 'benefits_category_2depth_category',
    params: Pick<EventParamsCustom, 'category_id'>,
  ): void
  /**
   * [카테고리 레이어] 베네핏 플러스 슬롯 별 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_category_plus',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 일반리스트 2뎁스 레이어 내 각 2뎁스 클릭 수 -> 카테고리 ID
   */
  presetLogEvent(
    eventName: 'benefits_list_normal_layer_2depth_category',
    params: Pick<EventParamsCustom, 'category_id'>,
  ): void
  /**
   * 일반리스트 각 3뎁스 별 클릭 수 -> 카테고리 ID
   */
  presetLogEvent(
    eventName: 'benefits_list_normal_3depth_category',
    params: Pick<EventParamsCustom, 'category_id'>,
  ): void
  /**
   * 일반리스트 내 슬롯 별 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_list_normal_benefits',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 추천리스트 내 슬롯 별 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_list_recommend_benefits',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 큐레이션리스트 내 슬롯 별 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_list_curation_benefits',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 각 베네핏 상세 페이지에서 베네핏 사용하기/코드보기 버튼 클릭 수 -> 베네핏&멤버 ID
   *
   * - [제외 Case]
   *   1. 사용하기/코드보기 버튼 클릭했는데, 입주예정자인 경우는 제외
   *   2. 코드보기 버튼 클릭했는데, 개인화코드가 소진된 경우는 제외
   */
  presetLogEvent(
    eventName: 'benefits_detail_use',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 베네핏 코드 노출된 컨테이너에서 베네핏 사용하기 버튼 클릭 수 -> 베네핏&멤버 ID
   */
  presetLogEvent(
    eventName: 'benefits_detail_container_use',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 코드 복사 버튼 클릭 수 -> 베네핏&멤버 ID
   */
  presetLogEvent(
    eventName: 'benefits_detail_container_codecopy',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * [검색][검색레이어] 각 인기검색어 클릭 수 -> 검색어 ID
   */
  presetLogEvent(
    eventName: 'benefits_search_popularword',
    params: Pick<EventParamsCustom, 'popularword_id'>,
  ): void
  /**
   * 검색결과 내 모든 슬롯 클릭 수 -> 베네핏 ID
   */
  presetLogEvent(
    eventName: 'benefits_searchlist_benefits',
    params: Pick<EventParamsCustom, 'benefits_id'>,
  ): void
  /**
   * 공간 상세 팝업 > 지점이용안내 클릭 수
   */
  presetLogEvent(eventName: 'details_branchinfo'): void
  /**
   * 예약내역 상세_지점이용안내 클릭 수
   */
  presetLogEvent(eventName: 'reservation_history_detail_branchinfo'): void
  /**
   * 예약내역 상세_예약 내역 공유하기 버튼 클릭수
   */
  presetLogEvent(eventName: 'reservation_history_detail_share'): void
  presetLogEvent(
    eventName: EventName,
    params?: Partial<EventParamsCustom>,
  ): void {
    switch (eventName) {
      case 'GNB_home':
      case 'GNB_account':
      case 'GNB_selectbranch':
      case 'GNB_selectbranch_other':
        this.logEvent(eventName, {
          category: 'GNB',
          action: 'click',
        })
        break

      case 'LNB_reservation':
      case 'LNB_reservation_history':
      case 'LNB_benefits':
      case 'LNB_groupmanagement_membermanagement':
      case 'LNB_groupmanagement_creditsmanagement':
      case 'LNB_contact':
        this.logEvent(eventName, {
          category: 'LNB',
          action: 'click',
        })
        break

      case 'home_reservation_history':
      case 'home_FAQ':
      case 'home_notice_main':
      case 'home_notice':
      case 'notice_details':
      case 'notice_details_back':
      case 'home_operationguide':
      case 'operationguide_top_image_scroller':
      case 'operationguide_top_image_thumbnail':
      case 'operationguide_copy1':
      case 'operationguide_slot_expand':
      case 'FAQ_search':
      case 'FAQ_category':
      case 'FAQ_slot_expand':
      case 'FAQ_details':
      case 'main_quote':
      case 'main_onboarding':
      case 'onboarding_printer':
      case 'onboarding_reservation':
      case 'onboarding_group':
        this.logEvent(eventName, {
          category: 'home',
          action: 'click',
        })
        break

      case 'account_logout':
      case 'account_myaccountupdate':
      case 'account_resetpassword':
      case 'account_selectgroup':
      case 'account_enter_invitationlink':
        this.logEvent(eventName, {
          category: 'account',
          action: 'click',
        })
        break

      case 'reservation_selectcalendar':
      case 'reservation_todaybutton':
      case 'space_list_selectsort_default':
      case 'space_list_selectsort_smallnumber':
      case 'space_list_selectsort_largenumber':
      case 'space_list_selectsort_lowfloor':
      case 'space_list_selectsort_highfloor':
      case 'space_list_selectsort_atoz':
      case 'space_list_selectsort_ztoa':
      case 'space_list_selectsort_lowcapacity':
      case 'space_list_selectsort_highcapacity':
      case 'reservation_credits':
      case 'reservation_popup_calendar':
      case 'reservation_popup_starttime':
      case 'reservation_popup_starttime_enter':
      case 'reservation_popup_endtime':
      case 'reservation_popup_endtime_enter':
      case 'reservation_details_profile':
      case 'reservation_spacecategory_mvmr':
      case 'reservation_spacecategory_mr':
      case 'reservation_spacecategory_rz':
      case 'reservation_spacecategory_sr':
      case 'reservation_spacecategory_st':
      case 'reservation_spacecategory_lg':
      case 'reservation_spacecategory_es':
      case 'reservation_spacecategory_mb':
      case 'reservation_filter_capacity':
      case 'reservation_timefiltering':
      case 'reservation_filter_facilities':
      case 'details_branchinfo':
        this.logEvent(eventName, {
          category: 'reservation',
          action: 'click',
        })
        break

      case 'reservation_history_groupreservation':
      case 'reservation_history_groupreservation_download':
      case 'reservation_history_cancellationpolicy':
      case 'reservation_history_mydetail':
      case 'reservation_history_groupdetail':
      case 'reservation_history_calendar':
      case 'reservation_history_detail_branchinfo':
      case 'reservation_history_detail_share':
        this.logEvent(eventName, {
          category: 'reservation_history',
          action: 'click',
        })
        break

      case 'membermanagement_invitationlink_copy':
      case 'creditsmanagement_creditspolicy':
      case 'creditsmanagement_download':
        this.logEvent(eventName, {
          category: 'groupmanagement',
          action: 'click',
        })
        break

      case 'footer_about_us':
      case 'footer_media':
      case 'footer_businesspartnership_email':
      case 'footer_realestate_email':
      case 'footer_press_email':
      case 'footer_termsofservice':
      case 'footer_privacypolicy':
      case 'footer_SNS_facebook':
      case 'footer_SNS_instagram':
      case 'footer_SNS_naverblog':
      case 'footer_SNS_youtube':
        this.logEvent(eventName, {
          category: 'footer',
          action: 'click',
        })
        break

      case 'inquiry_register_tab':
      case 'inquiry_inquiries_tab':
      case 'LNB_list_inquiries':
        this.logEvent(eventName, {
          category: 'inquiry',
          action: 'click',
        })
        break

      case 'benefits_recommend':
      case 'benefits_main_category_all':
      case 'benefits_main_recommend_all':
      case 'benefits_main_search':
      case 'benefits_main_ask':
      case 'benefits_list_normal_search':
      case 'benefits_list_normal_2depth':
      case 'benefits_list_normal_selectsort':
      case 'benefits_list_normal_selectsort_latest':
      case 'benefits_list_normal_selectsort_Alphabet':
      case 'benefits_list_normal_selectsort_random':
      case 'benefits_list_normal_complaint':
      case 'benefits_list_recommend_search':
      case 'benefits_list_recommend_selectsort':
      case 'benefits_list_recommend_selectsort_latest':
      case 'benefits_list_recommend_selectsort_Alphabet':
      case 'benefits_list_recommend_selectsort_random':
      case 'benefits_list_curation_search':
      case 'benefits_detail_tab_howtouse':
      case 'benefits_detail_tab_description':
      case 'benefits_searchlist_search':
      case 'benefits_searchlist_benefitstab':
      case 'benefits_searchlist_selectsort':
      case 'benefits_searchlist_selectsort_latest':
      case 'benefits_searchlist_selectsort_Alphabet':
      case 'benefits_searchlist_selectsort_random':
      case 'benefits_searchlist_complaint':
        this.logEvent(eventName, {
          category: 'benefits',
          action: 'click',
        })
        break

      case 'benefits_category':
      case 'benefits_main_banner':
      case 'benefits_main_curation_benefits':
      case 'benefits_main_curation_all':
      case 'benefits_main_plus':
      case 'benefits_category_2depth_category':
      case 'benefits_category_plus':
      case 'benefits_list_normal_layer_2depth_category':
      case 'benefits_list_normal_3depth_category':
      case 'benefits_list_normal_benefits':
      case 'benefits_list_recommend_benefits':
      case 'benefits_list_curation_benefits':
      case 'benefits_detail_use':
      case 'benefits_detail_container_use':
      case 'benefits_detail_container_codecopy':
      case 'benefits_search_popularword':
      case 'benefits_searchlist_benefits':
        this.logEvent(eventName, {
          category: 'benefits',
          action: 'click',
          ...params,
        })
        break

      default:
        console.warn('정의되지 않은 이벤트입니다.')
        break
    }
  }
}

export default Analytics
