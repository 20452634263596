<template>
  <div :class="$style.container">
    <div :class="$style.contents">
      <img
        :class="$style.image"
        :src="images.error"
      />

      <div :class="$style.title">
        링크가 잘못되었거나 페이지가 삭제되었을 수 있습니다.
      </div>

      <div :class="$style.subtitle">링크가 올바른지 확인해보세요.</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'NotFound',
  setup() {
    const images = ref({
      error: require('@/assets/images/v2/error/error_etc_refresh.png'),
    })

    return {
      images,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  white-space: pre-line;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  .contents {
    text-align: center;

    .image {
      display: block;
      margin: 0 auto 32px;
      width: 368px;
    }

    .title {
      @include font_v2('ko', 22px, 700);
      color: $f-primary-black;
    }

    .subtitle {
      @include font_v2('ko', 15px, 400);
      color: $f-gray-50;
    }

    .btn {
      margin: 24px auto 0;
    }
  }
}
</style>
