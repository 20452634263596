import { FastfiveThumbnail } from '@fastfive-dev/frontend-library/dist/fastfive/libs/thumbnail'

import { DB_TARGET } from '@/env'

export const thumbnailMaker = new FastfiveThumbnail({
  /**
   * POC 기간동안에는 staging 환경에서만 사용하도록 설정
   */
  isEnabled: DB_TARGET === 'staging',
})
