import { HttpMultipleCallDetector } from '@fastfive-dev/frontend-library/dist/fastfive/utils'
import {
  clearHttpCache,
  indexedDbStorage,
} from '@pkg/shared/dist/axios/cache-interceptor/indexed-db-storage'
import { TrackResponseTime } from '@pkg/shared/dist/axios/interceptors/track-response-time'
import axios, { AxiosRequestConfig } from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import { Subject, throttleTime } from 'rxjs'

import { createService as createAuthService } from '@/api/auth'
import { createBannerService } from '@/api/banners'
import { createBenefitService } from '@/api/benefits'
import { createBenefitCategoryService } from '@/api/benefits/category'
import { createBenefitCurationService } from '@/api/benefits/curation'
import { createBenefitRegionService } from '@/api/benefits/region'
import { createBenefitSearchKeywordService } from '@/api/benefits/search-keyword'
import { createBranchGuidesService } from '@/api/branchs/guides'
import { createService as createCommunityEvents } from '@/api/community-events'
import { createService as createCommunityNewsfeeds } from '@/api/community-newfeeds'
import { createService as createFileService } from '@/api/file'
import { createInquiryService } from '@/api/inquiry'
import { createFAQService } from '@/api/inquiry/faq'
import { createWorkAnywhereService } from '@/api/inquiry/workAnywhere'
import { createService as createMemberRoles } from '@/api/member-roles'
import { createOccupationService } from '@/api/occupations'
import { createQuoteService } from '@/api/quotes'
import { createService as createSetting } from '@/api/setting'
import { createService as createUserService } from '@/api/user'
import { createService as createVersionService } from '@/api/version'
// import Cookie from 'js-cookie';
import { API_BASE_URL } from '@/env'
import { ApiBaseResponse } from '@/interfaces/api'
import { failResponse } from '@/libs/api/custom-responses'
import { setRedirectUrl } from '@/libs/storage/helpers/redirect-url'
import { datadogLogs } from '@/plugins/datadog'
import router from '@/router'
import { useAppConfigStore } from '@/store/modules/app-config'
import { useGlobalStore } from '@/store/modules/global'
import { useGlobalLoadingStore } from '@/store/modules/global-loading'
import { useSessionStore } from '@/store/modules/session'

export const baseURL = API_BASE_URL

const options: AxiosRequestConfig = {
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 100000,
}

const instance = axios.create(options)

TrackResponseTime.setupInterceptor(instance, {
  onResult: (result) => {
    const msg = `[API] ${result.formatedText}`

    switch (result.type) {
      case 'warning':
        datadogLogs.logger.info(msg, result)
        break

      case 'slow':
        console.warn(msg)
        datadogLogs.logger.warn(msg, result)
        break
    }
  },
})

export const apiClient = setupCache(instance, {
  storage: indexedDbStorage,
  ttl: 0,
})

/**
 * 기존 코드 호환성을 위한 변수
 */
export const client = apiClient

const networkError$ = new Subject<Partial<ApiBaseResponse>>()

const httpMultipleCallDetector = new HttpMultipleCallDetector()

networkError$
  .pipe(
    throttleTime(1000, undefined, {
      leading: true,
      trailing: false,
    }),
  )
  .subscribe(async (response) => {
    const sessionStore = useSessionStore()
    const globalLoadingStore = useGlobalLoadingStore()
    const globalStore = useGlobalStore()
    const route = router.currentRoute?.value

    switch (response.errorCode) {
      // 세션만료
      case 'INVALID_SESSION': {
        globalLoadingStore.finishAll(1000)

        if (route && route.name === 'CreateInquiryWorkAnywhere') {
          router.push({
            name: 'CreateInquiryWorkAnywhere',
          })
          break
        }

        if (route && route.name !== 'SignIn') {
          setRedirectUrl(route.fullPath)
        }

        response.message = '세션이 만료되어 로그인 화면으로 이동합니다.'

        await sessionStore.clearSession()

        router.push({
          name: 'SignIn',
          query: {
            errorMessage: response.message || '',
          },
        })
        break
      }

      case 'SERVICE_UNAVAILABLE': {
        globalLoadingStore.finishAll(1000)
        globalStore.isServiceUnavailableForServerChecking = true
        break
      }
    }
  })

apiClient.interceptors.request.use(
  async (config) => {
    const appConfigStore = useAppConfigStore()
    const controller = new AbortController()

    if (/^(post|put|patch|delete)$/i.test(config.method || '')) {
      await clearHttpCache()
    }

    if (await appConfigStore.checkSystemMaintenance()) {
      controller.abort()
    }

    try {
      httpMultipleCallDetector.detectWithAxios(config)
    } catch (error) {
      console.warn(error)
    }

    return {
      ...config,
      signal: controller.signal,
    }
  },
  async (error) => {
    clearHttpCache()
    throw error
  },
)

// Add a response interceptor
apiClient.interceptors.response.use(
  async (response) => {
    return response
  },
  async (error) => {
    clearHttpCache()

    const data = error?.response?.data
    const response = failResponse()
    const statusCode = error?.response?.status || 0 // 서버 응답이 없으면 axios에서 0으로 줌

    if (statusCode === 401) {
      response.errorCode = 'INVALID_SESSION'
      response.message = 'Invalid session'
    }

    if (data?.errorCode) {
      response.errorCode = data.errorCode
    }

    if (data?.message) {
      response.message = data.message
    }

    switch (response.errorCode) {
      case 'INVALID_SESSION': // 세션만료
      case 'SERVICE_UNAVAILABLE': // 점검
        networkError$.next(response)
        break
    }

    throw error
  },
)

export const auth = createAuthService(client)
export const user = createUserService(client)
export const memberRoles = createMemberRoles(client)
export const setting = createSetting(client)
export const file = createFileService(client)
export const communityEvents = createCommunityEvents(client)
export const communityNewsfeeds = createCommunityNewsfeeds(client)
export const version = createVersionService(client)
export const branchGuidesApi = createBranchGuidesService(client)
export const bannersApi = createBannerService(client)
export const quoteApi = createQuoteService(client)
export const inquiryApi = createInquiryService(client)
export const faqApi = createFAQService(client)
export const workAnywhereApi = createWorkAnywhereService(client)
export const benefitCategoryApi = createBenefitCategoryService(client)
export const benefitApi = createBenefitService(client)
export const benefitCurationApi = createBenefitCurationService(client)
export const benefitRegionApi = createBenefitRegionService(client)
export const benefitSearchKeywordApi = createBenefitSearchKeywordService(client)
export const occupationApi = createOccupationService(client)
