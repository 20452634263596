import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill", "stroke"]
const _hoisted_3 = ["stroke"]
const _hoisted_4 = ["stroke"]
const _hoisted_5 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("rect", {
      x: "2.75",
      y: "4.75",
      width: "18.5",
      height: "16.5",
      rx: "2.25",
      fill: _ctx.color,
      stroke: _ctx.color ? _ctx.color : _ctx.stroke,
      "stroke-width": "1.5"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M9 12.7778L11.25 15L15 11",
      stroke: _ctx.stroke,
      "stroke-width": "1.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_3),
    _createElementVNode("path", {
      d: "M7 2.75V4.75",
      stroke: _ctx.color ? _ctx.color : _ctx.stroke,
      "stroke-width": "1.2",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_4),
    _createElementVNode("path", {
      d: "M17 2.75V4.75",
      stroke: _ctx.color ? _ctx.color : _ctx.stroke,
      "stroke-width": "1.2",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_5)
  ], 8, _hoisted_1))
}