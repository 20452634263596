import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Plugin } from 'vue'

import { fromKST, fromUTC, reservationTimeFormat } from '@/libs/date'

export const enum Timezone {
  'Asia/Seoul' = 'Asia/Seoul',
}

dayjs.locale('ko')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.tz.setDefault(Timezone['Asia/Seoul'])

export const dayjsPlugin: Plugin = {
  install: (app) => {
    app.config.globalProperties.dayjs = dayjs
    app.config.globalProperties.$fromKST = fromKST
    app.config.globalProperties.$fromUTC = fromUTC
    app.config.globalProperties.$reservationTimeFormat = reservationTimeFormat
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    dayjs: typeof dayjs
    $fromKST: typeof fromKST
    $fromUTC: typeof fromUTC
    $reservationTimeFormat: typeof reservationTimeFormat
  }
}
