import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Suspense as _Suspense, withCtx as _withCtx, KeepAlive as _KeepAlive, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_container = _resolveComponent("header-container")!
  const _component_navigation_menu_component = _resolveComponent("navigation-menu-component")!
  const _component_NetworkError = _resolveComponent("NetworkError")!
  const _component_GuestGuide = _resolveComponent("GuestGuide")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_footer_component = _resolveComponent("footer-component")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_BenefitSearchModal = _resolveComponent("BenefitSearchModal")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    ($setup.isLogin)
      ? (_openBlock(), _createBlock(_component_header_container, {
          key: 0,
          class: _normalizeClass(_ctx.$style.header),
          "onClick:logo": $setup.logGnbHome,
          "onClick:logout": _cache[0] || (_cache[0] = () => $setup.analytics.presetLogEvent('account_logout')),
          "onClick:myPage": _cache[1] || (_cache[1] = () => $setup.analytics.presetLogEvent('account_myaccountupdate')),
          "onClick:resetPassword": _cache[2] || (_cache[2] = 
        () => $setup.analytics.presetLogEvent('account_resetpassword')
      ),
          "onClick:invitation": _cache[3] || (_cache[3] = 
        () => $setup.analytics.presetLogEvent('account_enter_invitationlink')
      ),
          "onClick:profileMenu": _cache[4] || (_cache[4] = (isShowProfile) => $setup.logGnbAccount(isShowProfile)),
          "onChange:memberGroup": _cache[5] || (_cache[5] = 
        () => $setup.analytics.presetLogEvent('account_selectgroup')
      )
        }, null, 8, ["class", "onClick:logo"]))
      : _createCommentVNode("", true),
    ($setup.isLogin)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.$style.sideContainer)
        }, [
          _createVNode(_component_navigation_menu_component, {
            class: _normalizeClass(_ctx.$style.navigation),
            "onClick:routerLink": _cache[6] || (_cache[6] = (routeName) => $setup.logLnb(routeName))
          }, null, 8, ["class"])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.mainContainer)
    }, [
      ($setup.networkError?.state?.error)
        ? (_openBlock(), _createBlock(_component_NetworkError, { key: 0 }))
        : ($setup.isDisallowedRouteForGuestGroup)
          ? (_openBlock(), _createBlock(_component_GuestGuide, { key: 1 }))
          : (_openBlock(), _createBlock(_component_router_view, { key: 2 }, {
              default: _withCtx(({ Component, route }) => [
                (_openBlock(), _createBlock(_KeepAlive, { include: $setup.keepAliveComponentNames }, [
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.meta.usePathKey ? route.path : undefined
                      }))
                    ]),
                    _: 2
                  }, 1024))
                ], 1032, ["include"]))
              ]),
              _: 1
            })),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.footerContainer)
      }, [
        _withDirectives(_createVNode(_component_footer_component, {
          class: _normalizeClass(_ctx.$style.footer)
        }, null, 8, ["class"]), [
          [_vShow, $setup.isShowFooter]
        ])
      ], 2),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          ($setup.toast.show)
            ? (_openBlock(), _createBlock(_component_Toast, {
                key: 0,
                type: $setup.toast.type,
                message: $setup.toast.message
              }, null, 8, ["type", "message"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      ($setup.isLogin)
        ? (_openBlock(), _createBlock(_component_BenefitSearchModal, { key: 3 }))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}